<template>
  <div class="list-e" v-loading="loading">
    <div v-html="content" class="vhtml"></div>
    <div class="file-box">
      <ul>
        <li v-for="(item, index) of attFileList" :key="index" class="file-li">
          <div>
            <img v-if="item.type == 'pdf'" :src="pdf" alt="" class="tb-icon">
            <img v-else-if="item.type == 'ocx'" :src="ocx" alt="" class="tb-icon">
            <img v-else-if="item.type == 'lsx'" :src="lsx" alt="" class="tb-icon">
            <img v-else :src="image" alt="" class="tb-icon">
            <span class="file-title" :title="item.remark">{{item.remark}}</span>
          </div>
          <div class="file-li-icon cursor" @click="unload(item.path, item.remark)">
            <img :src="unlaodIcon" alt="">
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getInfoById } from '@/api/web'
import { fileDownload } from '@/api/login.js'

export default {
  components: {},
  name: 'ListE',
  data () {
    return {
      loading: false,
      content: '',
      attFileList: [],
      image: require('../../../assets/images/icon_image.png'),
      pdf: require('../../../assets/images/icon_pdf.png'),
      ocx: require('../../../assets/images/icon_doc.png'),
      lsx: require('../../../assets/images/icon_xls.png'),
      unlaodIcon: require('../../../assets/images/unload_a.png')
    }
  },
  watch: {
    '$route.fullPath': function (n, o) {
      if (n) {
        this.initialData()
      }
    }
  },
  created () {
    if (this.$route.query.columnId) {
      this.initialData()
    }
  },
  mounted () {},
  methods: {
    unload (path, name) {
      fileDownload(path, name)
    },
    initialData (id) {
      this.loading = true
      getInfoById({ id: this.$route.query.columnId }).then(res => {
        this.loading = false
        if (res.code === '000') {
          this.content = res.result.content
          if (res.result.attFileList !== null) {
            res.result.attFileList.forEach(element => {
              element.type = element.path.substr(element.path.length - 3, element.path.length - 1)
            })
            this.attFileList = res.result.attFileList
          } else {
            this.attFileList = []
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.list-e{
  padding: 25px;
  min-height: 520px;
  position: relative;
}
.file-box{
  padding: 20px 0;
}
.file-li{
  padding: 0 65px 0 25px;
  height: 40px;
  position: relative;
  background-color: #f8f8f8;
  border-radius: 4px;
  margin-bottom: 5px;
}
.file-li:hover{
  background-color: #f0f3fa;
}
.file-title{
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}
.file-li-icon{
  height: 20px;
  width: 20px;
  position: absolute;
  top: 10px;
  right: 25px;
}
.file-li-icon img{
  display: block;
  height: 100%;
  width: 100%;
}
.tb-icon{
  height: 18px;
  width: 18px;
  margin-right: 10px;
}
</style>
